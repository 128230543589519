<template>
  <div class="pt-6 pb-8 md:py-10 md:mt-8 bg-gray-100">
    <div class="px-6 md:bg-gray-100 responsive_container md:responsive_container-md ">
      <h1 class="section_title">
        {{ custom.title }}
      </h1>
      <h3 class="text-2xl leading-tight md:leading-normal font-bold">
        {{ custom.subtitle }}
      </h3>

      <div class="w-full flex flex-col md:pt-2 md:flex-row items-start justify-between mt-4">
        <div class="w-full md:w-2/3 md:pr-8 text-left leading-normal">
          <p v-html="custom.description" class="text-lg" />
        </div>
        <div class="w-full md:w-2/3 md:pl-8 mt-6 md:mt-0 md:pt-8 md:pb-10 text-left text-xl md:border-l border-gray leading-normal">
          <div class="w-full flex items-start">
            <Video
              :sources="custom.video.src"
              :poster="custom.video.img"
              :skip-backward="-20"
              :skip-forward="55"
              :auto-play="false"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Video from "@/components/views/Video.vue";
  export default {
    components: { Video },
    props: ["custom"]
  };
</script>
