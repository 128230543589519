<template>
  <div class="p-4 pt-8 md:py-8 md:pt-8 md:mt-10 bg-gray-100 w-full overflow-hidden" :id="custom.id">
      <h1 class="responsive_container md:responsive_container-md section_title leading-tight mx-0 md:mx-0 md:pb-4 md:mb-6">Learning Resources</h1>
    <div
      class="responsive_container md:responsive_container-md flex flex-col md:flex-row"
      :class="{ reverse: custom.text.position == 'left' }"
    >
    <div class="flex-col w-full px-3 md:w-1/2">
      <div v-if="custom.image.url" class="section_image md:section_image-md md:mt-12">
        <img class="w-full" :src="getAsset(custom.image.url)" />
      </div>

    </div>


        <div class="w-full py-6 md:pb-3 md:w-1/2 md:pl-4 md:pt-0 text-lg">
  

          <p class="font-bold px-6 md:px-6 bg-gray-200 text-xl p-4 mt-4 mb-6">Right now our priority is mobilisation around the Covid19 crisis response.</p>
          
          <p class="px-2 md:px-4 text-xl">We predict that this pandemic will have a lasting impact on how we all work.  Thriving in the new landscape means looking beyond the next fancy tool. It requires a conscious effort to get socialised in the new digital collaboration contexts available to us.</p>


        </div>

        <div v-if="custom.text.buttons" class="action_container">
          <a class="action" v-for="item in custom.text.buttons" :href="item.url" :style="styleObj(item)" :key="item.url" target="_blank">
            {{item.text}}
          </a>
        </div>


  </div>
</div>
</template>

<script>
export default {
  props: ["custom", "html"],
  methods: {
    getAsset(file) {
      return require("@/assets/" + file);
    },
    styleObj(item) {
      var styleObj = {
        background: '#000',
        color: '#fff'
      };
      if (item.background) {
        styleObj["background"] = item.background;
      };
      if (item.color) {
        styleObj["color"] = item.color;
      };
      return styleObj;
    }
  }
};
</script>
<style lang="scss">
  ul.list {
    @apply p-4 pt-6 bg-gray-100 rounded-lg;
    border: none !important;
    li {
      @apply py-3 px-2 pr-6 border-b;
       &:first-child {
      @apply pt-0;
      }
      &:last-child {
      border: none
      }
    }
  }

  .subtitle {
  h2 {
  color: orangered;
}
}

</style>

