<template>
  <div class="flex flex-col w-full">
    <Navigation style="margin-bottom: 60px" :data="selectedComponents" />
    <div v-for="section in data.sections" :key="section.title" :id="section.id">
      <Hero v-if="section.type == 'hero'" :custom="section" />
 <NGI v-if="section.type == 'ngi'" :baseUrl="data.baseUrl" :custom="section" />
      <Course v-if="section.type == 'course'" :baseUrl="data.baseUrl" :custom="section" />
      <Current v-if="section.type == 'current'" :baseUrl="data.baseUrl" :custom="section" id="current" />
      <Custom v-if="section.type == 'custom'" :custom="section" html=true  />
      <Courses v-if="section.type == 'courses'" :baseUrl="data.baseUrl" :custom="section" id="ask" />
      <Discussion v-if="section.type == 'discussion'" :baseUrl="data.baseUrl" :custom="section" id="checkin" />
      <Edgeryders v-if="section.type == 'edgeryders'" :custom="section" id="edgeryders"  />
      <Events v-if="section.type == 'events'" :baseUrl="data.baseUrl" :custom="section"  />
      <Form v-if="section.type == 'form'" :baseUrl="data.baseUrl" :custom="section" />
      <HowItWorks v-if="section.type == 'howItWorks'" :custom="section" id="edgeryders" />
      <Jobs v-if="section.type == 'jobs'" id="business" />
         <Academy
        v-if="section.type == 'academy'"
        :custom="section"
        :key="section.title"
        id="academy resources"
      /> 
      <Development v-if="section.type == 'development'" :custom="section" html=true id="resources"  />
      <Mission v-if="section.type == 'mission'" :baseUrl="data.baseUrl" :custom="section" id="impact" />
      <People v-if="section.type == 'people'" :baseUrl="data.baseUrl" :custom="section" />
      <Subscribe v-if="section.type == 'subscribe'" title="Subscribe to our mailing list" />
      <Partners v-if="section.type == 'partners'" :custom="section" />
      <Share v-if="section.type == 'share'" :baseUrl="data.baseUrl" :custom="section" />
      <Stories v-if="section.type == 'stories'" :baseUrl="data.baseUrl" :custom="section" />
      <Topics v-if="section.type == 'topics'" :baseUrl="data.baseUrl" :custom="section" />
      <Users v-if="section.type == 'users'" :baseUrl="data.baseUrl" :custom="section" id="members" />
    </div>

    <Terms />
  </div>
</template>

<script>
import data from "@/data/covid19.json";
import NGI from "@/components/NGI.vue";
import Discussion from "@/components/Discussion.vue";
import Course from "@/components/Course.vue";
import Courses from "@/components/Courses.vue";
import Academy from "@/components/Academy.vue";
import Current from "@/components/Current.vue";
import Custom from "@/components/Custom.vue";
import Edgeryders from "@/components/Edgeryders.vue";
import Development from "@/components/Development.vue";
import Subscribe from "@/components/views/Subscribe.vue";
import Events from "@/components/Events.vue";
import Form from "@/components/Form.vue";
import Hero from "@/components/Hero.vue";
import HowItWorks from "@/components/HowItWorks.vue";
import Jobs from "@/components/Jobs.vue";
import Mission from "@/components/Mission.vue";
import Navigation from "@/components/Navigation.vue";
import Partners from "@/components/Partners.vue";
import People from "@/components/People.vue";
import Share from "@/components/Share.vue";
import Stories from "@/components/Stories.vue";
import Terms from "@/components/Terms.vue";
import Topics from "@/components/Topics.vue";
import Users from "@/components/Users.vue";

export default {
  name: "home",
  data() {
    return { data };
  },
  components: {
    Discussion,
    NGI,
    Course,
    Courses,
    Current,
    Custom,
    Academy,
    Development,
    Edgeryders,
    Events,
    Form,
    Hero,
    Subscribe,
    HowItWorks,
    Jobs,
    Mission,
    Navigation,
    Partners,
    People,
    Share,
    Stories,
    Terms,
    Topics,
    Users
  },
  computed: {
    selectedComponents() {
      return this.data.sections
        .filter(({ id }) => id)
        .map(({ id, title }) => ({ id, title }))
    }
  }
};
</script>
