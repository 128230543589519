<template>
  <div class="wrapper current_wrapper px-6 md:px-24 mt-4 bg-gray-100 md:pt-10">
    <div class="pb-3 mb-2 flex flex-col items-center md:pb-4 md:border-b md:flex-row ">
      <div class="flex items-center w-full border-b pb-3 mb-4 md:border-none md:pb-0 md:mb-0 md:w-auto">
        <div class="current"></div> 
        <h1 class="leading-tight font-bold text-3xl"> {{ custom.title }}</h1>
      </div>
      <span class="text-2xl leading-tight font-light py-2 md:leading-normal md:pl-4 md:ml-4 md:border-l md:border-gray-200">{{ custom.subtitle }}..</span>

    </div>


    <div class="w-full md:py-4 flex flex-col md:flex-row">
      <div class="sidebar md:mr-4">
        <ul class="upcoming h-20 mb-4 md:mb-0 md:px-0 md:h-auto text-left">
          <li v-html="custom.events.title" class="pb-3 p-4 text-xl" @click="activate('events')" :class="{active: active == 'events'}" />
          <li v-html="custom.conversations.title" class="pb-3 p-4 text-xl" @click="activate('conversations')" :class="{active: active == 'conversations'}" />
          <li v-html="custom.calls.title" class="pb-3 p-4 text-xl" @click="activate('calls')" :class="{active: active == 'calls'}" />
        </ul>

      </div>

      <div class="w-full px-0 md:px-0 md:w-2/3 p-2 md:p-0 relative text-left events" v-if="active == 'events'">
        <swiper :options="sliderOptions" ref="mySwiper" v-if="events.length">
          <swiper-slide v-for="(story, index) in events" :key="index" :index="index">
            <div class="story bg-cover bg-center flex flex-col shadow-lg rounded-lg" style="height: 400px; width: 100% !important; margin: 0 auto !important; background-repeat: no-repeat; background-color: RGBA(66, 103, 178, 1.00)" :style="{ backgroundImage: 'url(' + story.image + ')' }">
              <p class="px-5 w-full md:w-auto pb-0 event_date" v-if="story.date">{{story.date | formatDate }}</p>
              <a :href='story.url' target="_blank" class="mt-auto text-left p-4 md:p-12 md:mx-6 pb-3 text-white z-20 relative z-9 ">
                <h4 class="font-body text-2xl border-b border-white-50 pb-4 mb-3 px-0 md:text-2xl font-bold text-left md:px-0 text-white leading-tight" style="text-shadow: 0px 1px 2px rgba(0,0,0,.9)">{{story.title}} </h4>
                <p class="px-0 pr-0 md:pr-0 pb-0 font-body">{{story.excerpt.substring(0,200)}}...</p>
                <span class="likes font-body">{{story.likes}}</span>
              </a>
            </div>
          </swiper-slide>
        </swiper>

        <div v-else class="w-full h-full text-2xl font-bold flex items-center justify-center" style="color: rgba(0,0,0,0.3)">
            <h3>There are currently no events scheduled</h3>
        </div>

        <div class="slider-arrow left hidden md:block" @click='back()'></div>
        <div class="slider-arrow right hidden md:block" @click='forward()'></div>
      </div>

      <div class="w-full px-0 md:px-0 md:w-2/3 p-2 md:p-0 text-left conversations" v-if="active == 'conversations'">
        <div class="entry" v-for="(entry, index) in activeConversations" :key="index">
          <div class="box" :style="{'background': colors[index]}">
            <a :href="entry.url" target="_blank"><h3>{{entry.title}}</h3></a>
          </div>
          <div class="mx-4 b-4 relative">
            <Profile class='profile rounded-lg' :data='entry.author' text="Started by" />
          </div>
        </div>
      </div>

      <div class="w-full px-0 md:px-0 md:w-2/3 p-2 md:p-0 text-left conversations" v-if="active == 'calls'">
        <div class="entry" v-for="(entry, index) in calls" :key="index">
          <div class="box" :style="{'background': colors[index]}">
            <div class="time">{{entry.event.start | formatDateShort}} @ {{entry.event.start | formatTime}}</div>
            <h3>
              <a :href="entry.url">{{entry.title}}</a>
            </h3>
          </div>
          <div class="mx-4 relative">
            <Profile class='profile' text="Hosted by" :data="entry.author" :key="index" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { swiper, swiperSlide } from "vue-awesome-swiper";
import Profile from "@/components/Profile.vue";
import moment from "moment";
import axios from "axios";
export default {
  data() {
    return {
      events: [],
      conversations: [],
      calls: [],
      activeIndex: 0,
      colors: ["#845EC2", "#74b14f", "#6ea6be", "#fca524", "#f9530f", "#7ac58d", "#e83e68", "#ea7e4e", "#81669a"],
      active: 'conversations',
      sliderOptions: {
        autoplay: true,
        speed: 1200,
        delay: 500,
        slidesPerView: 1,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            spaceBetween: 30,
            slidesPerColumnFill: "row",
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        breakpoints: {
          640: {
            slidesPerView: 1,
            slidesPerColumn: 1,
            slidesPerGroup: 1,
            centeredSlides: true,
            spaceBetween: 30,
            slidesPerColumnFill: "row",
          }
        }
      }
    };
  },
  filters: {
    formatDate: function(value) {
      return moment(String(value)).format("dddd MMMM Do YYYY");
    },
    formatDateShort: function(value) {
      return moment(String(value)).format("DD MMMM YYYY");
    },
    formatTime: function(value) {
      return moment(String(value)).format("h:mma");
    }
  },
  methods: {
    activate:function(el){
      this.active = el;
    },
    getTags(tags){
      let array = tags.map(function(tag) {
        return tag.name
      })
      return array
    },
    back() {
      this.$refs.mySwiper.swiper.slidePrev();
    },
    forward() {
     this.$refs.mySwiper.swiper.slideNext();
    },
    getJson(value) {
      const doc = new DOMParser().parseFromString(value, "text/html");
      var json = [...doc.querySelectorAll('code')].map(code => code.textContent);
      var final = String(json).replace(/\n/g, " ");
      if (final !== '') {
          var obj = JSON.parse(final);
          return obj;
        } else {
          return null
        }
      }
  },
  computed: {
    activeConversations() {
      return this.conversations.slice(0, 5);
    },
    activeCalls() {
      return this.calls.slice(0, 5);
    }
  },
  components: {
    swiper,
    swiperSlide,
    Profile
  },
  props: ["custom", "baseUrl"],
  mounted() {
    axios.get(`${this.baseUrl}/webkit_components/topics.json?tags=${this.custom.events.tags}&per=8`).then(({ data }) => {
      this.events = data
    })
    axios.get(`${this.baseUrl}/webkit_components/topics.json?tags=${this.custom.conversations.tags}&per=8`).then(({ data }) => {
      this.conversations = data
    })
    axios.get(`${this.baseUrl}/webkit_components/topics.json?serializer=event&tags=${this.custom.calls.tags}&per=100`).then(({ data }) => {
      var self = this;

      var calls = data.filter(function(obj) {
          var tags = self.getTags(obj.tags);
          var x = tags.includes('call');
          if (x && obj.event) {
            return obj
          }
        })

        this.calls = calls;
    })
  }
};
</script>

<style type="text/css" lang="scss" scoped>
.slider-arrow {
  top: 0%;
  position: absolute;
  z-index:99999;
  border-radius: 7px 0 0 7px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 30' %3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M17.1 4.6A1.5 1.5 0 0 0 15 2.4l-8.5 8.1a2 2 0 0 0 0 3l8.5 8.1a1.5 1.5 0 1 0 2.1-2.2L9.4 12l7.7-7.4z' fill='white'/%3E%3C/svg%3E") no-repeat rgba(255,255,255,0.05) 43% 55%;
  width: 50px;
  height: 100%;
  background-size: 50%;
  &.left {
    left: 0px;
    background-position: 43% 50%;
  }
  &.right {
    left: auto;
    right: 0px;
    transform: scale(-1);
    background-position: 45% 51%;
  }
  &:hover {
    cursor: pointer;
  }
  &.left:active {
    transform: scalex(1.05)
  }
  &.right:active {
    transform: scalex(-1.05)
  }
}
.question {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 125'%3E%3Cpath fill='%23fff' d='M50 4.3a45.8 45.8 0 10.1 91.6A45.8 45.8 0 0050 4.2zm4.7 71.3H44.5V66h10.2v9.6zm6.6-25.8c-3 2.2-6.2 4-6.8 8.1v2.7H45v-3.2c.5-6 3.3-8.8 6.1-11 2.7-2.1 5.1-3.7 5.1-7.5 0-4.2-2.2-6.3-6-6.3-5 0-7 4.2-7.1 9.1H32.8c.2-10 6.8-17.3 17-17.3 13.2 0 17.4 8.2 17.4 13.5a13 13 0 01-5.9 11.9z'/%3E%3C/svg%3E") no-repeat;
  width: 35px;
  height: 35px;
}
.now {
  min-width: 330px !important;
  max-width: 330px !important;
}
.upcoming {
  border-radius: 10px !important;
  overflow: hidden;
  height: auto !important;
  li {
    font-weight: bold;
    background: rgba(0,0,0,0.07);
    &:last-child {
      border-radius: 0 0 10px 10px;
    }
    &:hover {
      cursor: pointer;
    }
    &.active {
      background: rgba(0,0,0,0.9);
      color: white;
      position: relative;
    }
  }
}
.conversations {
  columns: 2;
  @for $i from 1 to 10 {
    .entry:nth-child(#{$i}) {
      animation-delay: $i * 0.05s !important;
    }
  }
  .entry {
    height: auto;
    opacity: 0;
    width: 100%;
    position: relative;
    padding-bottom: 20px;
    break-inside: avoid;
    animation: scale-easeInElastic 1.5s reverse forwards;

    .profile {
      overflow: hidden;
      position: absolute;
      bottom: 20px;
    }
    .box {
      border-radius: 10px 10px 0 0;
      color: white;
      border-radius: 10px !important;
      padding: 0px 0 50px;
      position: relative;
      overflow: hidden;
      font-weight: bold;
      .time {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 125'%3E%3Cswitch%3E%3Cg%3E%3Cpath fill='%23fff' d='M50 2.5a47.5 47.5 0 100 95 47.5 47.5 0 000-95zm26.1 65.1c-.9 2.2-2.5 4.4-3.5 5.4-5.7 5.7-21 .6-33.6-12-12.6-12.6-17.7-27.8-12-33.6 1-1 3.3-2.6 5.4-3.5 1.6-.7 3.5-.2 4.6 1.2l6.3 8a4 4 0 01-.5 5.4 94 94 0 00-3 2.7c-2.4 2.4 1.1 7.5 6.4 12.8 5.3 5.3 10.3 8.9 12.8 6.4a94 94 0 002.7-3 4 4 0 015.4-.5l8 6.3c1.3.8 1.7 2.7 1 4.4z'/%3E%3C/g%3E%3C/switch%3E%3C/svg%3E") no-repeat rgba(255,255,255,0.2) 15px 70%;
        height: 50px;
        display: flex;
        align-items: center;
        padding-left: 50px;
        width: 100%;
        background-size: 24px;
      }

      .footer {
        background: white;
        width: 100%;
        height: 50px;
      }
      h3 {
        margin: 20px;
        font-size: 1.3rem;
        line-height: 1.6rem;
        padding-right: 50px;
      }
    }
  }
}

.story {
  position: relative;
  overflow: hidden;
  align-items: flex-start !important;
  .event_date {
    background: #000;
    display: inline;
    width: auto;
    font-weight: bold;
    position: relative;
    z-index: 999;
    margin: 20px 40px;
    box-shadow: 0 1px 10px rgba(0, 0, 0, 0.4);
    align-self: flex-start !important;
    border-radius: 5px;
    text-align: center;
    padding: 12px 15px 10px;
    color: white;
    margin-bottom: 10px !important;
  }
  .likes {
    display: inline-block;
    background: white;
    padding: 12px 15px 10px 30px;
    border-radius: 5px;
    margin: 2px 0px 0 5px;
    color: white;
    font-size: 1rem !important;
    font-weight: bold;
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 100 125'%3E%3Cpath fill='white' d='M99.8 31.2c-1.4-16-12.8-27.7-27-27.7a26.9 26.9 0 00-23 13.3A26 26 0 0027.2 3.5C13 3.5 1.6 15.2.2 31.2c-.2.7-.6 4.5.8 10.6a45.2 45.2 0 0013.8 23l34.9 31.7 35.4-31.6A45.2 45.2 0 0099 41.8c1.4-6.1 1-9.8.8-10.6z'/%3E%3C/svg%3E") no-repeat 0 70%;
    background-size: 20px;
  }
  &:after {
    position: absolute;
    width: 100%;
    height: 100%;
    bottom: 0;
    content: '';
    z-index: 1;
    background: linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.4990371148459384) 75%, rgba(0,0,0,0) 100%);
  }
}
.recent_post {
  height: 250px;
  width: 250px;
  overflow: hidden;
  margin: 0;
  display: inline-block;
  float: left;
  background: #000;
  position: relative;
  background-color: #000 !important;
  background-size: cover !important;
  border-radius: 10px;
  box-shadow: 0 1px 20px rgba(0, 0, 0, 0.4);
  &.animated {
    animation: scale-easeInElastic 1.5s reverse forwards;
  }
  &:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 1;
    background: #000;
  }
  .date {
    color: white;
    border: 1px solid white;
    border-radius: 10px;
    position: relative;
    font-size: 15px !important;
    width: auto;
    display: inline-block;
    padding: 6px 14px 4px;
    z-index: 1;
    top: 30px;
    left: 20px;
  }
  .post_excerpt {
    color: white !important;
    overflow: hidden;
    height: 20%;
    position: absolute;
    bottom: 0;
    background: #09192b;
    font-size: 15px;
    padding: 4% 5% 25% !important;
    z-index: 1;
    width: 100%;
    transition: transform 0.65s ease;
  }
  a {
    padding: 0 20px 0;
    top: 30px;
    opacity: 1;
    z-index: 2;
    text-decoration: none !important;
    font-weight: bold !important;
    color: white !important;
    display: block;
    line-height: 30px !important;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.7);
    position: relative;
    font-size: 21px !important;
  }
  .thumbnail {
    position: absolute;
    top: 0;
    widh: 100%;
    height: 100%;
    left: 0;
    z-index: 2;
    opacity: 0.2;
    background-size: cover !important;
    background-repeat: no-repeat !important;
  }
}
#stories {
  padding-top: 30px;
  padding-bottom: 0;
  h1 {
    text-align: center;
    color: black;
    width: 80% !important;
    padding: 12px;
    float: none;
    border-bottom: 1px solid #ddd !important;
    margin: 0px auto 20px !important;
    display: block !important;
  }
  .featuredStories {
    .swiper-button-prev,
    .swiper-button-next {
      top: 40% !important;
    }
    .swiper-button-prev {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%232c86b8'%2F%3E%3C%2Fsvg%3E");
    }
    .swiper-button-next {
      background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%232c86b8'%2F%3E%3C%2Fsvg%3E");
    }
    .swiper-slide {
      display: flex;
      align-items: center;
    }
    .featured_post {
      width: 80% !important;
      margin: 0 auto !important;
      height: auto;
      display: inline-block !important;
      a {
        text-decoration: none;
        color: #2c86b8 !important;
        h3 {
          color: #2c86b8 !important;
        }
      }
      .excerpt a {
        text-decoration: underline !important;
      }
      .footer {
        border-radius: 10px;
        display: inline-flex;
        width: auto !important;
        margin: 5px 0 0 0;
        overflow: hidden;
        box-shadow: 0 1px 0px rgba(0, 0, 0, 0.1);
        p {
          float: left;
          padding: 13px 20px 10px;
          margin: 0;
          height: 100%;
          font-size: 0.9em;
          background: rgba(0, 0, 0, 0.02);
          border-right: 1px solid #efefef;
        }
        a {
          padding: 13px 20px 10px;
          height: 100%;
          font-size: 0.9em;
          border: none;
          background: orangered;
          color: white !important;
        }
      }
      .photo {
        width: 33%;
        float: left;
        margin-right: 4%;
        height: 400px;
        background-color: #dfdbe5;
        position: relative;
        overflow: hidden;
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%239C92AC' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
        .image_fill {
          width: 100%;
          height: 100%;
          background-size: cover !important;
          position: absolute;
          top: 0;
          left: 0;
        }
      }
      .summary {
        width: 63%;
        float: left;
        text-align: left !important;
      }
      h3 {
        font-size: 1.7em;
        font-weight: bold;
        line-height: 1.4em;
        margin: 10px 0 0 0;
        color: black;
        width: 100%;
        text-align: left !important;
      }
      .excerpt {
        font-size: 1.1em;
        margin: 10px 0 0 0;
      }
    }
  }
  .other_stories {
    width: 80%;
    margin: -40px auto 0;
    padding: 0;
    .swiper-wrapper {
      width: 100% !important;
    }
    .swiper-slide {
      width: 31% !important;
      margin: 0% 1.1% 2%;
      .recent_post {
        width: 100% !important;
      }
    }
    h1 {
      width: 100%;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    }
  }
}
@media (max-width: 640px) {
  .upcoming {
    overflow: auto;
    position: relative;
    display: flex;
    width: 80%;
    align-self: flex-start;
    min-width: none !important;
    max-width: none !important;
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    li {
      float: left;
      display: inline-block;
      width: auto;
      padding: 14px 20px;
      border-right: 1px solid rgba(0,0,0,0.05);
      &:first-child {
        border-radius: 10px 0 0 10px;
      }
      &:last-child {
        border-radius: 0 10px 10px 0;
        border-right: none;
      }
    }
  }
  .conversations {
    columns: 1 !important;
  }
  .event_date {
    margin: 0 !important;
    width: 100% !important;
    text-align: center !important;
    padding: 14px 0 !important;
  }
}

.current {
  @apply mr-3;
  border-radius: 10px;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 50'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='white' d='M28 17.5h-5.4l2.3-10-12.9 13h6.2l-3.1 12z'/%3E%3C/svg%3E") center 200% no-repeat #6C63FF;
  background-size: 30px;
  width: 40px;
  height: 40px;
}

.current_wrapper {
  padding-top: 1.5rem !important;
  width: 100vw;
  overflow-x: hidden;
}

@media (min-width: 640px) {
.current_wrapper {
  padding-top: 2rem !important;
}
}

.swiper-container {
  border-radius: 10px;
}
</style>
