<template>
    <div class="responsive_container md:responsive_container-md">
   
      <div class="discussion md:mt-4 px-3 py-3 md:pb-6 md:pt-0 rounded-lg ">

        <div class="flex flex-col md:flex-row items-start justify-between px-3 md:px-6 text-left text-xl leading-normal">
    
          <div style="flex-basis: 45%; flex-shrink: 0">
            <h1 v-if="custom.title" class="text-2xl font-bold border-b pb-3 mb-4">
            {{ custom.title }}
            </h1>
            <div v-if="custom.subtitle" class="text-lg md:text-xl mb-6 md:mb-4" v-html="custom.subtitle">
            </div>


          <a href="https://edgeryders.eu/t/status-report-whats-up-with-you" target="_blank" class="text-white text-lg bg-orange font-semibold rounded-lg px-4 py-3 leading-normal">
            {{ custom.submit }}
          </a>
          </div>

          <div class="discussion_container flex flex-col w-full content-align mt-4 pt-5 md:mt-0 md:w-1/2" style="flex-shrink: 0">
            <div class="w-full flex flex-col md:flex-row md:items-center md:content-center md:mt-4 pb-0 md:pb-6">
                <div class="animate-avatar mb-4 md:mb-8 md:mr-8 w-20 h-20 flex-none bg-white rounded-full shadow-lg border-4 border-white overflow-hidden object-cover bg-cover"  :style="{ backgroundImage: `url(${getAvatar(discussion.posts[slide]['cooked'])})` }" :key="slide"></div>
              <div class="animate-message border-2 md:border-none p-3 md:p-4 md:p-8 md:pb-3 w-full flex-grow bg-white rounded-lg shadow-xl bubble" :key="slide">
                <a :href="getUrl(discussion.posts[slide]['cooked'])" target="_blank"><p v-html="getStatus(discussion.posts[slide]['cooked'])" class="text-base md:text-lg" /></a>
                <div class="text-base md:text-lg md:text-lg mt-4 flex items-stretch items-center md:h-12">
                  <a :href="'https://edgeryders.eu/u/' + getUser(discussion.posts[slide]['cooked'])" target="_blank" class="font-bold pr-4 w-full border-t pt-3 border-gray-200 flex items-center">@{{ getUser(discussion.posts[slide].cooked) }}</a>
                </div>
              </div>
            </div>

          </div>

 

      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";
import axios from "axios";

export default {
  props: ["custom", "baseUrl"],
  data() {
    return { 
      discussion: {},
      slide: 0
    }
  },
  methods: {
    getStatus(value) {
      var div = document.createElement("div");
      div.innerHTML = value;
      var text = div.textContent || div.innerText || "";
      var final = String(text).replace("Status Report: What's Up With You?", '').replace("#featured", '');
      return final
    },
    getImgData(value) {
      var final = String(value).replace(/\n/g, " ");
      const doc = new DOMParser().parseFromString(final, "text/html");
      var json = doc.getElementsByTagName('img')[0];
      return json;
    },
    getAvatar(value) {
      var json = this.getImgData(value);
      return json.src.replace('40', '200');
    },
    getUser(value) {
      var json = this.getImgData(value);
      return json.src.replace('https://edgeryders.eu/user_avatar/edgeryders.eu/', '').replace(/[^\w+].*$/,'')
    },
    getUrl(value) {
            var final = String(value).replace(/\n/g, " ");
            const doc = new DOMParser().parseFromString(final, "text/html");
             var json = [...doc.querySelectorAll('a')].map(code => code.href).filter(item => item.includes('https://edgeryders.eu/t/status-report-whats-up-with-you/'))[0];
            return json
    },
    next(index) {
      var slideCount = this.discussion.posts.length;
      var self = this;
      if (index == slideCount - 1) {
        self.slide = 0
      } else {
        self.slide = self.slide + 1;
      };
    }
  },
  filters: {
    formatDate: function(value) {
      return moment(String(value)).format("DD/MM/YY");
    }
  },
  created() {
    axios.get(`${this.baseUrl}/webkit_components/topics/${this.custom.discussionId}.json`).then(({ data }) => {
      this.discussion = data.discussion
    });
  },
  mounted() {
    var self = this;
    this.interval = setInterval(function() {
      self.next(self.slide);
    }, 7000);
  }
};
</script>

<style lang="scss" scoped>


.bubble {
  position: relative;
  border-radius: .4em;
}
.bubble:after {
  content: '';
  position: absolute;
  top: 0;
  left: 40px;
  border: 20px solid transparent;
  border-bottom-color: #fff;
  border-top: 0;
  margin-left: -20px;
  margin-top: -20px;
}
@media (min-width: 640px) {
.bubble:after {
  content: '';
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  height: 0;
  border: 20px solid transparent;
  border-right-color: #fff;
  border-left: 0;
  border-top: 0;
  margin-top: -10px;
  margin-left: -20px;
}
 }
 .discussion {
 padding-top: 2rem !important;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  5% {
  opacity: 0;
    transform: translateY(20px);
}
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}


@keyframes fadeOut {
  0% {
    opacity: 1;
    transform: translateY(0px);
  }
  100% {
    opacity: 0;
    transform: translateY(-20px);
  }
}

.discussion_container {
  height: 380px;
  width: 40%;
  left: 50%;
  position: absolute;
  overflow: hidden;
}

.animate-avatar {
    animation: scale-easeInElastic 1.5s reverse forwards, fadeOut 1.7s forwards 5.3s;
}

.animate-message {
    animation: fadeUp 1s forwards, fadeOut 1s forwards 5.5s;
}

@media (max-width: 640px) {
.discussion_container {
  width: 90%;
  height: 400px;
  position: relative;
  left: 0;
}
.bubble:after {
  display: none;
}
}
</style>
