<template>
  <div class="md:pt-6 md:pt-3">
    <div class="">

      <div class="flex flex-col md:px-28 md:pt-4 md:flex-row items-center md:mx-0 md:my-4">
        <Video class="w-full md:w-3/5 md:ml-20 md:pl-10" :poster="getAsset('mooc.png')" sources="https://ercrowdfund.netlify.com/media/MOOC_1.1506ea8d.mp4" />
        <div class="text-2xl font-bold leading-tight md:leading-normal md:w-3/5 mt-4 md:mt-0 md:px-0 md:pl-10">
         <p class='pb-4 px-6 md:px-2 md:pr-20 md:mr-12 text-lg md:text-xl font-light leading-normal'>In 2019 Edgeryders produced a <a href="https://edgeryders.eu/t/distributed-collaboration-manual/11263" target="_blank" class="font-bold underline">comprehensive manual</a> for Distributed Collaboration with Europe’s largest public private partnership for climate innovation. </p>

         <h3 class="w-full px-6 md:px-0 md:pl-2 md:mr-20 md:pr-20 text-lg md:text-xl leading-normal">Now we are creating an online course to cover the essentials of remote work and collaboration</h3>
        </div>
      </div>

      <Learn class="responsive_container md:responsive_container-md" :custom="custom.learn" />
<!-- 
      <h3 class="border-b pb-3 mb-4 mx-6 mt-2 md:mt-0 md:px-1 leading-tight md:leading-normal md:mx-8 color-black text-2xl font-bold">How you can access the course</h3>
      <p class='pb-4 px-6 md:px-8 text-xl md:pr-20'>Right now many of us are taking a financial hit. This includes the team who have put aside everything to quickly get the  course up and running as a service to us all. Whatever you can offer is much appreciated.</p>
      <p class='text-xl px-6 md:px-8 md:pr-20'>
      If enough people contribute time or money to cover the work involved, we can make it completely free to access for everyone who needs it! Contact us to learn more about how you can help: <a class="font-bold underline" href="mailto: nadia@edgeryders.eu">nadia@edgeryders.eu </a>
      </p> -->

    </div>
  </div>
</template>

<script>
import Video from "@/components/views/Video.vue";
import Learn from "@/components/Learn.vue";

export default {
  methods: {
    getAsset(file) {
      return require("@/assets/" + file);
    }
  },
  components: {
    Video,
    Learn
  },
  props: ["custom"]
}
</script>
