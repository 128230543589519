<template>
  <div>
       <div class="px-6 pt-6 md:pt-10 responsive_container md:responsive_container-md">
      <div class="flex flex-col md:flex-row justify-between md:pb-6">
      <div class="mx-4 mb-4 md:w-2/5">
        <img :src="getAsset('questions.svg')" />
      </div>
      <div class="md:w-1/2 text-lg font-bold">
        <div class="md:h-auto md:pl-0 md:py-10 md:border-gray-200 ">
          <p class="pb-4 text-2xl md:text-3xl leading-tight">
            Ask a question around a topic that matters to you right now.
          </p>
          <p class="pb-4 mb-2 text-xl">
            Others in the community can share what they know or offer a helpful new perspective.
          </p>
          <a href="http://edgeryders.eu/c/campfire" class="bg-orange px-4 py-3 text-white font-bold rounded-lg">Ask a question</a>

        </div>
      </div>
     </div>
    </div>

    <div class="flex flex-col md:mx-0 courses_container mt-8 mx-4 md:mt-4 md:mx-auto">
    <div
      class="flex courses md:courses-md" :class="{active: active}"
    >
      <div class="sidebar border-r md:sidebar-md" :class="{active: active}">
        <div class="sub_header">
          <h3 class="text-lg font-bold">ask</h3>
          <ul>
            <li class="tag active"><span>#covid-19</span></li>
          </ul>
        </div>
        <div class="sub_menu">
          <ol>
            <li
              v-for="(item, i) in questions"
              :key="item.title"
              @click="select(i)"
              :class="{ active: i == index }"
            >
              <span>{{ item.title }}</span>
            </li>
          </ol>
        </div>
      </div>

      <div class="w-full section_text relative section_slider px-0 md:section_slider-md" :class="{active: active}">
        <div class="back md:hidden bg-white" :class="{active: active}" @click="back"><div class="hand"></div>back to questions</div>
        <div class="scroll_container relative md:scroll_container-md">
        <div class="excerpt">
            <div class="flex flex-col px-4 pb-4 md:px-6 md:pt-3 bg-gray-100 question">
              <div class="flex flex-col">
              <h3 class="border-b pb-2 pt-3 leading-tight font-bold">
                <a :href="questions[index].url" target="_blank">{{questions[index].title}}</a>
              </h3>
              <div class="flex flex-col md:flex-row meta items-center">
                <Profile class="mt-2 mr-2 self-start" :data="questions[index].author" />
                <div class="flex self-start">
                <p class="data views">{{questions[index].views}}</p>
                <p class="data likes">{{questions[index].like_count}}</p>
                <p class="data responses">{{questions[index].reply_count}}</p>
                </div>
              </div>
              </div>

            </div>

            <div class="px-6 mt-4 leading-relaxed cooked">{{stripped(questions[index].cooked)}}</div>
        
         
        </div>
         <a class="respond w-full p-3 px-6 leading-tight flex items-center md:px-5 md:absolute bottom-0 font-bold border-t text-normal " :href="questions[index].url" target="_blank">
          <span class="mr-4 md:mr-2 respond_icon"></span>
              read more &amp; respond
            </a>
      </div>
       
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Profile from "@/components/Profile.vue";
import axios from "axios";
export default {
  data() {
    return {
      stories: [""],
      index: 0,
      questions: null,
      active: false,
      titles: ["community", "ethnography", "graphryder"]
    };
  },
  components: {
    Profile
  },
  methods: {
    getAsset(file) {
      return require("@/assets/" + file);
    },
    stripped(value) {
      var div = document.createElement("div");
      div.innerHTML = value;
      var text = div.textContent || div.innerText || "";

      var final = String(text).replace(/\n/g, " ").replace(/[\u2018\u2019]/g, "'").replace(/[\u201C\u201D]/g, '"');

      return final.substring(0,460) + '...';
    },
    select(value) {
      this.index = value;
      this.active = true;
    },
    back() {
      this.active = false;
    }
  },
  mounted() {
    axios.get(`${this.baseUrl}/webkit_components/topics.json?serializer=event&tags=call&tags=${this.custom.tags.join(',')}&per=8`).then(({ data }) => {
      this.questions = data
    })
  },
  props: ["custom", "baseUrl"]
};
</script>
<style lang="scss">
.question {
  background-image: gradient(linear, left top, right bottom, from(#f7f7f7), to(#fafafa));

  box-shadow: 0 2px 10px rgba(0,0,0,0.07);

}
.respond {
  background: linear-gradient(to bottom, #fafafa, #fff);
 background-size: 200% 100%;
color: orangered;

    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;

.respond_icon {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 100 125'%3E%3Cpath fill='orangered' d='M50 0A50 50 0 000 50a50 50 0 0050 50 50 50 0 0050-50A50 50 0 0050 0zm24.5 51.3L55.4 70.5a2.3 2.3 0 01-1.5.6 1.8 1.8 0 01-1.3-.6A1.9 1.9 0 0152 69V57.7H25V42.3h27V31a1.9 1.9 0 01.6-1.4 1.8 1.8 0 011.3-.6 1.9 1.9 0 011.4.6l19.2 19.1A1.9 1.9 0 0175 50a1.8 1.8 0 01-.5 1.3z'/%3E%3C/svg%3E") no-repeat 0 130%;
  width: 27px;
  height: 27px;
  margin-right: 0px;
  display: inline-block;
  background-size: 70%;
}
&:hover {
    background-position: 100% 0;
    moz-transition: all .4s ease-in-out;
    -o-transition: all .4s ease-in-out;
    -webkit-transition: all .4s ease-in-out;
    transition: all .4s ease-in-out;
    cursor: pointer;
}
}
.meta {
  p.data {
    @apply border px-2 mt-2 flex h-full items-center font-bold mr-2;
    border-radius: 5px;
    color: rgba(0,0,0,0.5);
    font-size: 12px;
    height: 31px;
  }
  .views {
   background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 125'%3E%3Cpath fill='rgba(0,0,0,0.5)' d='M89.7 47.4C85.3 42.4 69.5 26 50 26S14.7 42.4 10.3 47.4a3.8 3.8 0 000 5.1C14.7 57.6 30.5 74 50 74s35.3-16.4 39.7-21.5a3.8 3.8 0 000-5.1zM50 66.4c-9 0-16.4-7.4-16.4-16.4S41 33.6 50 33.6 66.4 41 66.4 50 59 66.4 50 66.4z'/%3E%3Cpath fill='rgba(0,0,0,0.5)' d='M50 38.6a11.4 11.4 0 100 22.8 11.4 11.4 0 000-22.8z'/%3E%3C/svg%3E") no-repeat 5px 4px;
    padding-left: 30px !important;
    background-size: 22px;
  }
  .likes {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1024 1280'%3E%3Cpath fill='rgba(0,0,0,0.5)' d='M925 369c0 59-23 113-64 154L512 873 163 523a216 216 0 01154-372c58 0 113 23 154 64l41 41 41-41a217 217 0 01372 154z'/%3E%3C/svg%3E") no-repeat 5px 6.5px;
    padding-left: 25px !important;
    background-size: 16px;
}
.responses {
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 512 640'%3E%3Cpath fill='rgba(0,0,0,0.5)' d='M512 396v26c-3 7-5 14-11 19-18 14-43 7-52-15-9-24-26-39-48-50-30-15-63-21-96-23l-78-1a23 23 0 00-3 0v62a39 39 0 01-2 11c-6 22-34 33-55 12L15 284c-7-6-13-13-15-22v-12c2-9 8-16 15-22l87-87 66-66c10-11 23-14 36-8s20 17 20 31v62h7c6 0 58 1 105 13 28 7 73 19 109 50 66 57 67 150 67 173z'/%3E%3C/svg%3E") no-repeat 5px 6.5px;
    padding-left: 25px !important;
    background-size: 15px;
}
}
.course_video {
  width: 100%;
}
.courses_container {
  border-radius: 10px;
  overflow: hidden !important;
  @apply border;
  max-width: 920px;
}

.courses {
  @apply relative;
  height: 500px;

  overflow: hidden !important;
  .header {
    background: #efefef;
    height: 20px;
  }
}


.scroll_container {
  height:88%;
  overflow-y: scroll;
  width: 100%;
}

.back_story .section_title {
  margin-top: 0px !important;
  margin-bottom: 20px !important;
}
.back_story {
  h3 {
    font-weight: 600;
    font-size: 25px;
  }
  .excerpt p {
    margin: 10px 0;
    font-size: 13px;
  }
  a {
    font-weight: bold;
    border-bottom: 1px dashed #000;
    padding-bottom: 2px;
  }
}

.back_story .section_text {
  /deep/ h3 {
    font-weight: 600;
    font-size: 25px;
  }
  .excerpt {
    @apply mt-4;
  }
  .excerpt p {
    font-size: 14px;
    margin: 10px 0;
  }
}

  .sub_header {
   background: url("data:image/svg+xml,%3Csvg viewBox='0 0 500 500' version='1' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='orangered' d='M0 250a250 250 0 1 1 500 0 250 250 0 0 1-500 0zm250 183V250H67a183 183 0 1 1 183 183z' fill-rule='evenodd'/%3E%3C/svg%3E") no-repeat 15px 53%, linear-gradient(180deg, rgba(237,237,237,.6) 0%, rgba(255,255,255,1) 100%), #fff;
    background-size: 24px, 100%;
    @apply w-full flex items-center border-b font-bold;
    padding-left: 30px;
     border-radius: 10px 10px 0 0;
    position: absolute;
    top: 0;
    z-index: 99999;
    h3 {
        @apply border-l border-gray-300 pl-2;
    font-size: 22px;
    color: orangered;
      @apply m-4;
    }
    ul {
      li {
        font-size: 13px;
        @apply bg-white rounded-lg p-2;
        &.active {
          @apply text-white;
          background: orangered;
        }
      }
    }
  }


.sub_menu {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.02);
  border: none;
  padding-top: 65px;
  position: relative;
  ol {
    list-style-type: none;
    font-size: 15px;
    @apply leading-tight;
    li {
      @apply p-4 border-b pr-6 font-bold leading-tight;
      background: white;
      width: 100%;
      &.tag span {
      background: orangered;
      padding: 10px;
      border-radius: 10px;
      color: white;
    }
    &:last-child {
      border: none;
    }
      span {
      }
      &:hover {
        cursor: pointer;
      }
      &.active {
        background: #fafafa;
        span {
          margin: 0;
          padding: 0;
        }
      }
    }
  }
}

.cooked {
  font-size: 15px;
}


@media (max-width: 640px) {

.sub_menu {
  ol {
    li {
      width: 85%;
    }
  }
}

}
</style>
