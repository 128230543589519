<template>
  <div>
    <div class="px-6 pt-6 responsive_container md:responsive_container-md md:px-5 md:pt-10">
      <h1 class="section_title font-bold border-b pb-3 md:pb-3 text-2xl leading-tight md:text-3xl ">Build Impact + Income</h1>
      <div class="flex flex-col md:flex-row items-start mb-6">
        <img :src="getAsset('oci_lab.png')" class="md:w-1/3 md:mt-3" />
      <p class="md:py-4 text-xl md:text-2xl font-light md:pl-8 md:pt-10">The Open Collective Intelligence Lab is a Peer to Peer online incubator to support entrepreneurs working for positive impact on people and planet. </p>
    </div>
      <h3 class="pb-3 text-2xl font-bold border-b mb-6 md:mt-4">Who the program is for</h3>
      <div class="flex flex-col md:flex-row items-start">
      <img :src="getAsset('oci_leader.svg')" class="md:w-1/3 md:mr-4 md:mt-2" />
      <div class="md:px-6 pt-4 md:pl-6 md:w-2/3 md:mb-4">
      <p class="pb-2 text-xl leading-tight md:leading-normal"><b>Project leaders: have an idea  for a potentially self-sustaining project/ social enterprise/venture</b></p>
      <ul class="list-disc pl-6 md:pl-4 md:pb-4 text-lg">
        <li>are in the process of validating their ideas</li>
        <li>are looking for mentors, partners or any kind of support</li>
        <li>willing to have shareholders, and commit to start new entreprises</li>
      </ul>
      </div>
      </div>
      <div class="flex flex-col md:flex-row items-start mt-4 border-t md:pt-4">
      <img :src="getAsset('oci_contributor.svg')" class="md:w-1/3 mt-3 mr-4" />
      <div class="md:px-6 pt-4 pb-3 md:pl-6 md:w-2/3 md:mb-4">
      <p class="pb-2 text-xl leading-tight md:leading-normal"><b>Project contributors: Do not have a project/venture idea</b></p>
      <ul class="list-disc pl-2 pl-6 md:pl-4 md:pb-4 text-lg">
        <li>Want to invest some time into building new paths to generate income for themselves</li>
        <li>Want to develop new skills</li>
        <li>Want to grow their professional network</li>
      </ul>
    </div>
  </div>
    <div class="flex flex-col md:flex-row items-start md:mt-4 border-t md:pt-4">
      <img :src="getAsset('oci_owner.svg')" class="md:w-1/3 mt-5 mr-4" />
    <div class="w-full md:pl-6 pt-4 md:w-2/3 mb-4">
      <p class="pb-2 text-xl leading-tight md:leading-normal"><b>Problem owners: have a problem that they care about</b></p>
      <ul class="list-disc text-lg pl-6 md:pl-4 md:pb-4">
        <li>Want to be involved in developing and testing solutions to the problem</li>
        <li>have some kind of leadership role in a context where the problem is very present</li>
        <li>are willing to invest financial or nonfinancial resources into self-sustaining solutions that come out of the incubator</li>
      </ul>
    </div>
    </div>
      <p class="pb-4 text-xl font-bold">Write to <a class="underline" href="mailto:ocilab@edgeryders.eu">ocilab@edgeryders.eu</a> for more information about the program &amp; partnership enquiries</p>
    </div>
  </div>
</template>
<script>

export default {
  methods: {
    getAsset(file) {
      return require("@/assets/" + file);
    }
  }
}
</script>
