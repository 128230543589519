<template>
  <div class="p-6 md:px-0 responsive_container md:responsive_container-md" id="edgeryders">
    <div class="section_title flex items-center">
      <div class="er_logo mr-3"></div>
            <h1 class="text-2xl leading-tight md:leading-normal md:text-4xl font-bold">{{ custom.title }}</h1>

    </div>
    <div class="w-full flex flex-col items-start justify-between bg-white">
      <div
        class="w-full flex flex-col md:flex-row md:px-0 md:mt-4 leading-normal"
      >
        <div
          class="w-full pt-0 md:w-1/2 md:px-6 md:px-0 md:pr-8 md:pt-2 text-left md:text-right md:border-r border-gray-200"
        >
          <h3
            class="hidden font-display text-2xl font-bold text-center md:inline-block md:text-right md:mb-2 mt-2"
          >
            And we are listening.
          </h3>
          <p class="text-lg">
            We are collecting your stories to better understand how we can level
            up impact of our efforts to fix socialecological, economic, and
            political challenges. We want to know what you have to say, and we
            want others to connect and collaborate with you.
          </p>
          <a href="https://edgeryders.eu/c/campfire" target="_blank"
            ><button
              class="text-white bg-primary border border-primary text-xm font-semibold rounded-lg px-4 py-3 mt-6 leading-normal"
            >
              Tell us what brings you here
            </button>
          </a>
        </div>
        <div class="md:w-1/2 flex flex-col md:pl-8 mt-4">
          <div class="w-full inline-block">
            <div class="icon automation"></div>
            <div class="icon activism"></div>
            <div class="icon healthcare"></div>
            <div class="icon ai"></div>
            <div class="icon elections"></div>
            <div class="icon network"></div>
            <div class="icon environment"></div>
            <div class="icon nomad"></div>
          </div>
        </div>
      </div>
      <div class="w-full md:flex md:px-0 text-left leading-normal">
        <div class="md:w-1/2 pt-4 md:pt-8 md:pr-8 md:border-r border-gray-200">
        <transition-group name="fade" tag="div" class="relative w-full slideshow">
        <div v-for="i in [currentIndex]" :key="i">
        <img :src="getAsset(currentImg)" />
        </div>
        </transition-group>
        </div>
        <div class="md:w-1/2 md:mt-3 px-0 pt-0 md:pl-8 md:pt-4">
          <h3
            class="font-display leading-tight text-2xl font-bold text-left md:leading-normal mb-2"
          >
            This is how it works.
          </h3>
          <p class="text-lg">
            Edgeryders is a community of individuals from different walks of life in 80 + countries. Members have been sharing their stories, their ideas, their problems, and their solutions since 2013. All these posts together are what we call our "collective intelligence".
          </p>
          <p class="text-lg mt-4">
            Our data analysis team uses open source methodologies and technologies to create interactive visualisations of the conversations as they evolve. The visualisations make it easier for everyone to find and connect across common interests and new insights. 
          </p>
          <p class="text-lg mt-4">
            This is all financed through revenue from research grants, consultancy and training for other organisations.
          </p>
          <a href="https://edgeryders.eu/c/campfire" target="_blank"
            ><button
              class="text-white bg-primary border border-primary text-xm font-semibold rounded-lg px-4 py-3 mt-6 leading-normal"
            >
              Connect with the community
            </button></a
          >
        </div>
      </div>
    </div>

    <div
        class="w-full md:flex pt-6 md:pt-0 md:px-12 md:pb-6 text-left leading-normal"
      >
        <div class="md:w-1/2 md:pr-12 md:text-right md:border-r border-gray-200">
          <h3 class="text-2xl mb-2 font-bold border-b pb-2 md:pb-4">Contact the organisation</h3>
          <p class="text-xl md:text-lg">
            The best way to learn more about Edgeryders research, technologies or services is a video or phone call. To schedule an appointment, write to <a href="mailto:kaja@edgeryders.eu" class="underline">kaja@edgeryders.eu</a>
          </p>
     
        </div>
        <div class="md:w-1/2 mt-8 md:mt-0 md:pl-8 md:flex md:items-center">
         
         <div class="w-full flex">
            <a class="social twitter" href="https://twitter.com/edgeryders" target="_blank"></a>
            <a class="social facebook" href="https://facebook.com/edgeryders" target="_blank"></a>
            <a class="social newsletter" href="mailto:info@edgeryders.eu" target="_blank"></a>
          </div>
        </div>
      </div>
    </div>

</template>

<style lang="scss">
.fade-enter-active,
.fade-leave-active {
  transition: all 0.9s ease;
  overflow: hidden;
  visibility: visible;
  position: absolute;
  opacity: 1;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.slideshow img {
  height:auto;
  width:100%
}

.slideshow {
  min-height: 200px;
}

.social {
  width: 5rem;
  height: 5rem;
  border-radius: 10px;
  background-color: #ddd;
  margin-right: 1rem;
  background-position: center center !important;
  background-repeat: no-repeat !important;
 &:hover {
 background-color: rgba(0,0,0,0.14);
} 
&.twitter {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='%231DA1F2' d='M459 152l1 13c0 139-106 299-299 299-59 0-115-17-161-47a217 217 0 0 0 156-44c-47-1-85-31-98-72l19 1c10 0 19-1 28-3-48-10-84-52-84-103v-2c14 8 30 13 47 14A105 105 0 0 1 36 67c51 64 129 106 216 110-2-8-2-16-2-24a105 105 0 0 1 181-72c24-4 47-13 67-25-8 24-25 45-46 58 21-3 41-8 60-17-14 21-32 40-53 55z'/%3E%3C/svg%3E");
  background-size: 40%;
}
&.facebook {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%234267B2' d='M279 288l14-93h-89v-60c0-25 13-50 53-50h40V6s-37-6-72-6c-73 0-121 44-121 125v70H23v93h81v224h100V288z'/%3E%3C/svg%3E");
  background-size: 20%;
}
&.newsletter {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512'%3E%3Cpath fill='rgba(0,0,0,0.7)' d='M502 191c4-3 10 0 10 5v204c0 27-21 48-48 48H48c-26 0-48-21-48-48V196c0-5 6-8 10-5l154 114c21 15 56 47 92 47s72-33 92-47l154-114zM256 320c23 0 57-29 73-41 133-97 143-105 174-129 6-5 9-12 9-19v-19c0-26-22-48-48-48H48C22 64 0 86 0 112v19c0 7 3 14 9 19 31 24 41 32 174 129 16 12 50 41 73 41z'/%3E%3C/svg%3E");
  background-size: 35%;
}
}


</style>
<script>
export default {
  data() {
    return {
      images: [
        "edgeryders_1.jpg",
        "edgeryders_2.jpg"
      ],
      timer: null,
      currentIndex: 0
    };
  },
   mounted: function() {
    this.startSlide();
  },
  methods: {
    startSlide: function() {
      this.timer = setInterval(this.next, 4000);
    },
    next: function() {
      this.currentIndex += 1;
    },
    prev: function() {
      this.currentIndex -= 1;
    },
    getAsset(file) {
      return require("@/assets/" + file);
    }
  },
  props: ["custom"],
  computed: {
    currentImg: function() {
      return this.images[Math.abs(this.currentIndex) % this.images.length];
    }
  }
}
</script>

