<template>
  <div class="py-8">
    <div class="responsive_container md:responsive_container-md px-6 md:px-0 md:pb-3">
      <h2 class="text-2xl md:text-3xl font-bold border-b pb-3 mb-3 text-left justify-start leading-tight md:leading-normal">{{ custom.title }}</h2>
      <h3 class="text-left text-xl font-bold mb-2 md:mb-4">Why share your journey?</h3>
      <p class="text-left text-lg" v-html="custom.description" />
      <a :href="`${baseUrl}/c/${custom.category}`" target="_blank">
        <button class="text-white bg-orange text-xm font-semibold rounded-lg px-4 py-3 mt-2 leading-normal">
          {{ custom.submit }}
        </button>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  props: ["baseUrl", "custom"]
};
</script>

<style lang="scss" scoped>
  h1 {
    text-align: center !important;
    display: block !important;
    color: #ff4500;
  }

  .call_to_action {

  }
</style>
