<template>
  <div class="w-full flex flex-col md:flex-row" :style="styleObject">

   <div class="hero_image md:hero_image-md " v-if="custom.image.url">
        <img :src="getAsset(custom.image.url)"  />
      </div>
    
         <div class="flex flex-col items-start w-full md:w-1/2 px-6 md:p-12 md:pt-16 text-left">
          <div>
            <h2 class="highlight md:py-4">Feeling a little.. boxed in?</h2>
          </div>
          <p class="text-2xl font-bold pr-4 leading-tight md:leading-normal mt-4 mb-2 md:mb-4 md:pr-20">Let's come together, make sense of the situation and help each other.</p>

    <scrollactive
        class="nav"
        active-class="active"
        :offset="80"
        :duration="800"
        bezier-easing-value=".5,0,.35,1"
      >
          <ul class="options w-full md:w-auto mb-4 md:mb-0 leading-tight md:leading-normal" >
            <li class="md:px-3"><a href="#checkin" class="scrollactive-item"> Check in with a human</a></li>
            <li><a href="https://edgeryders.eu/t/what-can-we-do-to-help-your-work-and-to-help-grow-a-network-of-mutual-aid-efforts/13011" target="_blank">Help with a community project</a></li>
            <li><a href="#ask" class="scrollactive-item">Ask a question</a></li>
          </ul>
        </scrollactive>
          
        </div>

  </div>
</template>

<script>
import moment from "moment";

export default {
  methods: {
    getAsset(file) {
      return require("@/assets/" + file);
    }
  },
  computed: {
    styleObject() {
      if (this.custom.background.url) {
        return {
          background: `url('${this.custom.background.url}')`,
          backgroundPosition: "cover"
        }
      } else if (this.custom.background.color) {
        return { 
          background: this.custom.background.color 
        }
      } 
      else {
        return { background: "#efefef" }
      }
    }
  },
  filters: {
    formatDate: function(value) {
      return moment(String(value)).format("MMMM Do YYYY");
    }
  },
  props: ["custom"]
};
</script>
<style lang="scss">
  .list {
    @apply rounded-lg pl-0;
    padding: 0;
    li {
      @apply py-3 px-0 border-b font-bold;
    }
  }
  .options {
  @apply w-full;
  li {
  @apply py-3 pr-4 border-b font-bold;
  background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' data-name='Layer 1' viewBox='0 0 100 125'%3E%3Cpath d='M98 44L65 11a8 8 0 10-12 12l20 19H8a8 8 0 000 16h65L53 77a8 8 0 0012 12l33-33a8 8 0 002-6 8 8 0 00-2-6z'/%3E%3C/svg%3E") no-repeat;
  background-position: 0px 60%;
  font-size: 18px;
  background-size: 18px;
  padding-left: 1.8rem;
  &:last-child {
  border: none;
}
}
}
h2.highlight {
  @apply inline text-2xl p-3 w-2/3 py-2 mb-0 bg-orange text-white font-bold;
  box-decoration-break: clone !important;
  display: inline !important;
}
@media (min-width: 640px) {
  h2.highlight {
    @apply py-3 w-2/3;
  }
    .options {
  @apply w-full;
  li {
  @apply pl-10;
  background-position: 10px 60%;
}
}
}
</style>
