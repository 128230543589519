<template>
  <div class="w-full flex items-center justify-center py-4 md:py-8" id="subscribe">
    <div class="wrapper pl-6 px:px-0 md:w-1/2 flex-col items-start justify-start">
      <div class="pr-8 email">
        <h1 class="flex flex-col items-start md:items-center md:flex-row">
          <div class="subscribe_icon md:mb-3 md:mr-3"></div>
          <h3 class="leading-tight md:text-3xl pt-2 md:pt-0 md:mb-3">{{title}}</h3>
        </h1>
        <div class="content md:content-md mt-4" v-html="text"></div>
        <vue-mailchimp-email-signup-form :elementId="'first-email-signup-form'" :url="'https://edgeryders.us2.list-manage.com/subscribe/post?u=2426561089e1f5ab32da252cd&amp;id=21ed4ca53d'" />
      </div>

      <div class="md:p-2 w-full mt-2 flex flex-col items-start md:flex-row md:items-center">
        <img :src="getAsset('mailchimp-gdpr.svg')" class="md:mr-3 " style="max-width: 60px" />
        <p class="text-xs mt-2 md:mt-0">We use Mailchimp as our marketing platform. By clicking subscribe, you acknowledge that your information will be transferred to Mailchimp for processing. <a class="underline" href="Learn more about Mailchimp's privacy practices here.">Learn more about Mailchimp's privacy practices here.</a></p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return { email: "" };
  },
  methods: {
    getAsset(file) {
      return require("@/assets/" + file);
    }
  },
  props: ["title", "text"]
};
</script>
<style lang="scss">
.wrapper {
  @apply pt-2 pb-3;
}
.subscribe_icon {
  width: 38px;
  height: 38px;
  min-width: 35px;
  min-height: 35px;
  background: url("data:image/svg+xml,%3Csvg width='50' height='40' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='white' d='M.1 4.1c.2-.9.6-1.7 1.2-2.4C1.9.9 2.9.4 4 .4h41.3C47.6.4 49.6 1.9 50 4v.1c.2 1.7-.2 3.3-1.1 4.7-1.1 1.6-2.5 3-4 4-4.7 3.1-9.3 6.3-13.9 9.6-1.3 1-2.6 1.8-4 2.5-1.3.6-2.6.5-3.8 0l-3.7-2.3a648.7 648.7 0 01-16.7-12C1.6 9.6.7 8.2.3 6.7 0 6-.1 5 0 4.2zm45.3 35.6H4.5A4.6 4.6 0 010 35V19.9v-7c2 2.3 4.6 3.6 7 5.3l11.8 8.3c1.2.9 2.6 1.5 4 2s3 .4 4.4 0c1.6-.6 3-1.3 4.4-2.3 5-3.6 10.3-7.1 15.4-10.8l3-2.3v22c-.1 2.5-2.1 4.5-4.6 4.6z' fill-rule='nonzero'/%3E%3C/svg%3E") no-repeat 50% 50% blue;
  border-radius: 100px;
  margin-right: 10px;
  background-size: 42% !important;
}
.subscribe-container {
  width: 100% !important;
}
#first-email-signup-formmc_embed_signup_scroll {
  width: 100%;
  display: flex;
}

.email .subscribe-container {
  width: 100% !important;
}
.email button {
  background: blue !important;
  color: white !important;
  @apply p-3 ml-3;
  margin: 0 !important;
}
.email input {
width: 100% !important;
}
#first-email-signup-formmc_embed_signup {
  width: 70%;
}
    .content {
      p {
         @apply text-lg mb-2 font-bold;
      }
      a {
        @apply underline;
      }
    }
#subscribe {
  @apply bg-gray-100;
  .wrapper {
    h1 {
      @apply text-2xl mb-3 pb-2 font-bold border-b;
      width: 100%;
      color: #0010FF;
      span {
        font-weight: 300;
      }
    }
    .email {
      @apply w-full items-start mb-2;
      input {
        @apply p-3 bg-white mb-3 mr-4 border;
        width: 100%;
        &:focus {
          outline: none;
          border: 1px solid #ddd;
        }
      }
    }

  }
}
$checkbox-diameter: 20px;
$checkbox-bg: #c8dbff;
$checkbox-bg-selected: #0034C9;
$checkbox-tick: #fff;
.checkbox-wrapper {
  @apply ml-1 mr-1;
  height: $checkbox-diameter;
  text-align: center;
}
.checkbox:not(last-of-type) {
  display: inline-block;
  margin-right: $checkbox-diameter;
}
.checkbox {
  position: relative;
  label {
    position: absolute;
    top: 0;
    left: 0;
    width: $checkbox-diameter;
    height: $checkbox-diameter;
    background-color: $checkbox-bg;
    border: 1px solid $checkbox-bg;
    border-radius: 10%;
    cursor: pointer;
    transition:
      background-color 300ms ease,
      colour 300ms ease,
      box-shadow 300ms ease;

    // tick
    &:after {
      content: '';
      position: absolute;
      top: 40%;
      left: 35%;
      width: 35%;
      height: 14%;
      opacity: 0;
      border-left: 1px solid $checkbox-tick;
      border-bottom: 1px solid $checkbox-tick;
      transform: rotate(-45deg);
      transition: opacity 300ms ease;
    }
  }

  input {
    visibility: hidden;
    // selected state bg
    &:checked + label {
      background-color: $checkbox-bg-selected;
      border-color: $checkbox-bg-selected;

      // selected state tick
      &:after {
        opacity: 1;
      }
    }
  }
}

@media (max-width: 640px) {
#first-email-signup-formmc_embed_signup_scroll {
  width: 100%;
  display: flex;
  flex-direction: column;
}
}
</style>
