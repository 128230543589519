<template>
  <div class="pt-6 md:section-md" :id="custom.id" :style="{background: custom.color}">
          <div v-if="custom.title" class="section_title mx-6 px-1 md:mx-4 md:pb-4">{{custom.title}}</div>

    <div
      class="wrapper md:wrapper-md md:mt-10 "
      :class="{ reverse: custom.text.position == 'left' }"
    >
    <div class="flex-col px-3 md:w-1/2">
      <div v-if="custom.image.url" class="section_image md:section_image-md md:mt-12" :style="{width: custom.image.width + 'px'}">
        <img class="w-full" :src="getAsset(custom.image.url)" />
      </div>

      <div v-if="custom.video.src" class="section_video">
        <video controls class="rounded-lg overflow-hidden">
          <source :src="custom.video.src" type="video/mp4" />

          Sorry, your browser doesn't support embedded videos.
        </video>
      </div>


      <div v-if="custom.subtitle" class="subtitle text-2xl md:text-2xl font-bold md:pt-2 px-2 md:mr-6" v-html="custom.subtitle">
        
      </div>

    </div>

<div class="w-full md:w-1/2 border-l md:pl-6 pt-0 md:pt-4">
  <div v-if="custom.text.title" v-html="custom.text.title" style="color: orangered;" class="text-xl mt-0 pt-0 font-bold px-5 leading-tight"></div>
  <div v-if="html" v-html="custom.text.content"></div>
</div>



  </div>
</div>
</template>

<script>
export default {
  props: ["custom", "html"],
  methods: {
    getAsset(file) {
      return require("@/assets/" + file);
    },
    styleObj(item) {
      var styleObj = {
        background: '#000',
        color: '#fff'
      };
      if (item.background) {
        styleObj["background"] = item.background;
      };
      if (item.color) {
        styleObj["color"] = item.color;
      };
      return styleObj;
    }
  }
};
</script>
<style lang="scss">
ul.list {
    @apply p-4 pt-1 bg-white rounded-lg;
    margin-top: 0 !important;
    border: none !important;
    background: white !important;
    li {
      @apply py-3 pr-6 border-b font-bold;
       &:first-child {
      @apply pt-0;
      }
      &:last-child {
      border: none
      }
    }
  }

  .subtitle {
  h2 {
  color: orangered;
}
}

</style>

